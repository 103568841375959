/* styles.css */

/* Container styles */
.image-container {
    overflow: hidden;
}

/* Image styles */
.filling-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: fill 0.6s ease-in-out alternate infinite;
}

@keyframes fill {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}