
.grad {
    background: linear-gradient(to right, #f32170, #ff6b08,
            #3dcf23, #eedd44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}